import React, { useState } from "react"
import { Modal } from "antd"
import { Header, Intro, Calendar, Events, Results, Excursion, Footer } from "./Components"
import "./Components/Calendar/Calendar.scss"

import "antd/dist/antd.css"

const App = () => {
  const [showModal, setShowModal] = useState(false)
  const openCalendarModal = () => setShowModal(true)
  const closeModal = () => setShowModal(false)

  return (
    <>
      {/* test */}
      <Header openCalendarModal={openCalendarModal} />
      <Intro openCalendarModal={openCalendarModal} />
      <Calendar />
      <Events />
      <Results />
      <Excursion />
      <Footer />

      <Modal visible={showModal} onOk={closeModal} onCancel={closeModal} footer={null} wrapClassName={"CalendarModal mobile"}>
        <Calendar showMobile />
      </Modal>
    </>
  )
}

export default App
