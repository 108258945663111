import React, { useState, useCallback, useMemo, useEffect } from "react"
import { Pagination, Image } from "antd"
import axios from "../../config/axios"

import useWindowSize from "../../hooks/useWindowSize"

import "./Events.scss"

const EventComponent = ({}) => {
  const size = useWindowSize()
  const getLimit = size.width <= 480 ? 1 : size.width > 480 && size.width <= 768 ? 2 : 3
  const [events, setEvents] = useState([])
  const [paginationData, setPaginationData] = useState({ totalItems: null, page: 1, limit: getLimit })
  const [selectedEvent, setSeceltedEvent] = useState(null)
  const onSelectEvent = useCallback((event) => setSeceltedEvent(event), [])

  useEffect(() => {
    const getEvents = async () => {
      try {
        const {
          data: { events, totalItems },
        } = await axios.get("/viewEvents/viewAll", { params: paginationData })
        if (!paginationData.totalItems) {
          setPaginationData({ ...paginationData, totalItems })
        }
        setEvents(events)
      } catch (err) {
        console.log("err", err)
      }
    }
    getEvents()
  }, [paginationData.page, paginationData.limit])

  useEffect(() => {
    if (size.width <= 480 && paginationData.limit !== 1) {
      setPaginationData({ ...paginationData, limit: 1 })
    } else if (size.width > 480 && size.width <= 768 && paginationData.limit !== 2) {
      setPaginationData({ ...paginationData, limit: 2 })
    } else if (size.width > 768 && paginationData.limit !== 3) {
      setPaginationData({ ...paginationData, limit: 3 })
    }
  }, [size.width, paginationData.limit])

  const onChange = (page) => setPaginationData({ ...paginationData, page })

  const renderEvents = useMemo(() => {
    return events.map(({ _id, image, description, name }) => (
      <div className="EventCard" key={_id}>
        <Image className="EventImage" src={image} />
        {/* <div className="MobileImage" style={{ backgroundImage: `url(${image})`}} /> */}
        <div className="EventDescription">{description}</div>
      </div>
    ))
  }, [events])

  return (
    <div className="EventsBlock" id="#events">
      <div className="Title">Анонс мероприятий</div>
      <div className="EventsWrapper">{renderEvents}</div>
      {paginationData.totalItems && paginationData.limit < paginationData.totalItems && (
        <div className="PaginationWrapper">
          <Pagination current={paginationData.page} total={paginationData.totalItems} pageSize={paginationData.limit} onChange={onChange} />
        </div>
      )}
    </div>
  )
}

export default EventComponent
