import React, { useEffect } from "react"
import { YMaps, Map, Placemark } from "react-yandex-maps"

import "./Excursion.scss"

const ExcursionComponent = ({}) => {
  let pano, skin
  useEffect(() => {
    pano = new window.pano2vrPlayer("ExcurtionContainet")
    skin = new window.pano2vrSkin(pano)
    pano.readConfigUrlAsync(process.env.PUBLIC_URL + "/pano.xml")

    pano.on("beforechangenode", (e) => {
      console.log("pano.getCurrentNode()", pano.getCurrentNode())
    })
  }, [])
  return (
    <div className="ExcursionBlock" id="#excursion">
      <div className="Title">Виртуальная экскурсия</div>
      <div className="MapsWrapper">
        <div className="ExcursionRow" id="ExcurtionContainet" />
        <div className="YMapRow">
          <YMaps>
            <Map width="100%" height="100%" defaultState={{ center: [47.253874, 38.916896], zoom: 15 }}>
              <Placemark geometry={[47.253874, 38.916896]} />
            </Map>
          </YMaps>
        </div>
      </div>
    </div>
  )
}

export default ExcursionComponent
