import React, { useState, useCallback, useMemo, useEffect } from "react"
import { Image, Modal, Pagination, Carousel } from "antd"
import axios from "../../config/axios"

import useWindowSize from "../../hooks/useWindowSize"

import "./Results.scss"

const ResultsComponent = ({}) => {
  const size = useWindowSize()
  const getLimit = size.width <= 480 ? 1 : 2
  const [results, setResults] = useState([])
  const [paginationData, setPaginationData] = useState({ totalItems: null, page: 1, limit: getLimit })
  const [selectedResult, setSeceltedResult] = useState(null)
  const onSelectResult = useCallback((event) => () => setSeceltedResult(event), [])
  const closeModal = () => setSeceltedResult(null)

  useEffect(
    () => {
      const getEvents = async () => {
        try {
          const {
            data: { results, totalItems },
          } = await axios.get("/viewResults/viewAll", { params: paginationData })
          if (!paginationData.totalItems) {
            setPaginationData({ ...paginationData, totalItems })
          }
          setResults(results)
        } catch (err) {
          console.log("err", err)
        }
      }
      getEvents()
    },
    [paginationData.page],
    paginationData.limit
  )

  useEffect(() => {
    if (size.width <= 480 && paginationData.limit !== 1) {
      setPaginationData({ ...paginationData, limit: 1 })
    } else if (size.width > 480 && paginationData.limit !== 2) {
      setPaginationData({ ...paginationData, limit: 2 })
    }
  }, [size.width, paginationData.limit])

  const onChange = (page) => setPaginationData({ ...paginationData, page })

  const renderResults = useMemo(() => {
    return results.map((result) => (
      <div className="ResultCard" key={result._id}>
        <Image
          className={`ResultImage ${paginationData.limit === 1 && "mobile"}`}
          height={paginationData.limit === 1 ? "auto" : 200}
          width={paginationData.limit === 1 ? 150 : "auto"}
          src={result.images[0]}
        />
        <div className="ResultDescription">
          {result.shortDesc}
          <div className="ShowMore" onClick={onSelectResult(result)}>
            Подробнее
          </div>
        </div>
      </div>
    ))
  }, [results, paginationData.limit])

  return (
    <>
      <div className="ResultsBlock">
        <div className="Title">Отчеты о мероприятиях</div>
        <div className="ResultRows">{renderResults}</div>
        {paginationData.totalItems && paginationData.limit < paginationData.totalItems && (
          <div className="PaginationWrapper">
            <Pagination current={paginationData.page} total={paginationData.totalItems} defaultPageSize={paginationData.limit} onChange={onChange} />
          </div>
        )}
      </div>
      <Modal visible={selectedResult} onOk={closeModal} onCancel={closeModal} footer={null} wrapClassName={"ResultModal"}>
        <Carousel autoplay dotPosition={"top"} dots={{ className: "CarouselDots" }}>
          {selectedResult && selectedResult.images.map((image) => <Image src={image} />)}
        </Carousel>
        <div className="ModalDesc">{selectedResult && selectedResult.longDesc}</div>
      </Modal>
    </>
  )
}

export default ResultsComponent
