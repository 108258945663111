import React from "react"
import AwesomeSlider from "react-awesome-slider"
import withAutoplay from "react-awesome-slider/dist/autoplay"
import "react-awesome-slider/dist/styles.css"
import "./Intro.scss"

const Icon360Src = process.env.PUBLIC_URL + "/assets/images/360_icon.svg"

const SliderMedia = [
  {
    source: process.env.PUBLIC_URL + "/assets/images/slider/intro_background.jpg",
  },
  {
    source: process.env.PUBLIC_URL + "/assets/images/slider/intro_ex1.jpg",
  },
  {
    source: process.env.PUBLIC_URL + "/assets/images/slider/intro_ex2.jpg",
  },
  {
    source: process.env.PUBLIC_URL + "/assets/images/slider/intro_ex3.jpg",
  },
]

const AutoplaySlider = withAutoplay(AwesomeSlider)

const Intro = ({ openCalendarModal }) => {
  const goTo = (section) => (_) => {
    const element = document.getElementById(section)

    if (element) {
      const boundary = element.getBoundingClientRect()
      window.scrollTo({
        top: boundary.top + window.pageYOffset,
        behavior: "smooth",
      })
    }
  }

  return (
    <div className="Intro" id="#main">
      <AutoplaySlider media={SliderMedia} play={true} cancelOnInteraction={false} interval={6000} buttons={false} />
      <div className="IntroTextBlock">
        <div className="TextBlockTitle">
          самый лучший <br />
          танцевальный зал <br /> в твоем городе
        </div>
        <div className="TextBlockButton" onClick={goTo("#contact")}>
          Связаться с нами
        </div>
        <div className="MobileButtonWrap">
          <div className="TextBlockButton mobile" onClick={goTo("#contact")}>
            Связаться с нами
          </div>
          <div className="Calendar" onClick={openCalendarModal}>
            Расписание
          </div>
        </div>
      </div>
      <img className="excursionIcon" alt="360" src={Icon360Src} onClick={goTo("#excursion")} />
    </div>
  )
}

export default Intro
