import React, { useState, useCallback, useMemo } from "react"
import VK from "./vk.png"
import Instagram from "./instagram.png"

import "./Footer.scss"

const FooterComponent = () => {
  const goTo = useCallback(
    (link) => (e) => {
      const win = window.open(link, "_blank")
      win.focus()
    },
    []
  )

  return (
    <div className="FooterBlock" id="#contact">
      <div className="FooterText Email">dancehall@eff-s.ru</div>
      <div className="FooterText">+7 (928) 900 01 44</div>
      <div className="FooterText">+7 (928) 173 74 89</div>
      <div className="FooterText">Адрес: г.Таганрог, ул. Дзержинского 165/9</div>
      <div className="FooterText">Режим работы: ежедневно с 10:00 до 21:00</div>
      <div className="IconsRow">
        <img className="Icon" src={VK} alt="vk" onClick={goTo("https://vk.com")} />
        <img className="Icon" src={Instagram} alt="instagram" onClick={goTo("https://www.instagram.com/dancehall.su/")} />
      </div>
      <div className="Description">
        <span>© 2020 Шепель О.Н.</span>
        <span>Использование либо копирование материалов сайта разрешено только с предварительного согласия правообладателя.</span>
      </div>
    </div>
  )
}

export default FooterComponent
