import React, { useCallback, useMemo } from "react"
import { Menu, Dropdown } from "antd"
import { MenuOutlined } from "@ant-design/icons"

import VK from "../Footer/vk.png"
import Instagram from "../Footer/instagram.png"

import "./Header.scss"

const logoSrc = process.env.PUBLIC_URL + "/assets/images/logo.png"

const Header = ({ openCalendarModal }) => {
  const scrollTo = (section) => (e) => {
    const element = document.getElementById(section)

    if (element) {
      const boundary = element.getBoundingClientRect()
      window.scrollTo({
        top: boundary.top + window.pageYOffset,
        behavior: "smooth",
      })
    }
  }

  const goTo = useCallback(
    (link) => (e) => {
      const win = window.open(link, "_blank")
      win.focus()
    },
    []
  )

  const menu = useMemo(
    () => (
      <Menu
        style={{
          background: "#1E1E1E",
        }}
        className="HeaderMenu"
      >
        <Menu.Item key="0">
          <div className="HeaderItem" onClick={scrollTo("#main")}>
            Главная
          </div>
        </Menu.Item>
        <Menu.Item key="1">
          <div className="HeaderItem" onClick={openCalendarModal}>
            Расписание
          </div>
        </Menu.Item>
        <Menu.Item key="2">
          <div className="HeaderItem" onClick={scrollTo("#events")}>
            Мероприятия
          </div>
        </Menu.Item>
        <Menu.Item key="3">
          <div className="HeaderItem" onClick={scrollTo("#contact")}>
            Контакты
          </div>
        </Menu.Item>
        <Menu.Item key="4">
          <div className="IconsRow">
            <img className="Icon" src={VK} alt="vk" onClick={goTo("https://vk.com")} />
            <img className="Icon" src={Instagram} alt="instagram" onClick={goTo("https://www.instagram.com")} />
          </div>
        </Menu.Item>
      </Menu>
    ),
    []
  )

  return (
    <header className="Header">
      <img className="HeaderImage" alt="logo" src={logoSrc} />
      <div className="MobileMenu">
        <Dropdown overlay={menu} trigger={["click"]} className="MenuIcon">
          <MenuOutlined />
        </Dropdown>
      </div>
      <div className="HeaderItems">
        <div className="HeaderItem" onClick={scrollTo("#main")}>
          Главная
        </div>
        <div className="HeaderItem" onClick={scrollTo("#events")}>
          Мероприятия
        </div>
        <div className="HeaderItem" onClick={scrollTo("#schedule")}>
          Расписание
        </div>
        <div className="HeaderItem" onClick={scrollTo("#contact")}>
          Контакты
        </div>
      </div>
    </header>
  )
}

export default Header
