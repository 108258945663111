import React, { useState, useCallback, useMemo, useEffect } from "react"
import { Calendar, momentLocalizer } from "react-big-calendar"
import { Avatar, Divider, Modal } from "antd"
import { LeftOutlined, RightOutlined } from "@ant-design/icons"
import moment from "moment"
import axios from "../../config/axios"
import useWindowSize from "../../hooks/useWindowSize"
import "moment/locale/ru"
import "./Calendar.scss"

const getPlural = (value) => {
  switch (value) {
    case 1:
      return "месяц"
    case 2:
    case 3:
    case 4:
      return "месяца"
    default:
      return "месяцев"
  }
}

const bgColors = ["#E0B0FF", "#F63BA5", "#D940D9", "#C71585", "#DA363B", "#9F9F9F"]

const localizer = momentLocalizer(moment)

const CalendarComponent = ({ showMobile }) => {
  const size = useWindowSize()
  const [events, setEvents] = useState([])
  const [selectedEvent, setSeceltedEvent] = useState(null)
  const [timeFrom, setTimeFrom] = useState(moment(new Date()).startOf("month").unix() * 1000)

  const showInModal = size.width <= 1024

  const Toolbar = useCallback((toolbar) => {
    const goToBack = () => {
      toolbar.date.setMonth(toolbar.date.getMonth() - 1)
      toolbar.onNavigate("prev")
    }

    const goToNext = () => {
      toolbar.date.setMonth(toolbar.date.getMonth() + 1)
      toolbar.onNavigate("next")
    }

    const [month, year] = toolbar.label.split(" ")

    return (
      <div className="Toolbar">
        <LeftOutlined className="ToolbarArrow" onClick={goToBack} />
        <div className="Label">
          {month} <span>{year}</span>
        </div>
        <RightOutlined className="ToolbarArrow" onClick={goToNext} />
      </div>
    )
  }, [])

  const MonthEvent = useCallback((props) => {
    return props.event.title
  }, [])

  useEffect(() => {
    const getDates = async () => {
      try {
        const { data } = await axios.get("/calendar/dates", { params: { timeFrom, timeTo: moment(timeFrom).add(1, "month").unix() * 1000 } })
        const filteredEvents = data.map(({ _id, subject, timeStart, timeEnd, ...rest }) => {
          const workStart = moment(rest.teacher.workStart).format("MMMM YYYY")
          const working = -moment(rest.teacher.workStart).diff(new Date(), "months")
          const years = Math.floor(working / 12)
          const months = working % 12
          const workLine = `${years ? years + " лет" : months + " " + getPlural(months)} ${months ? "и " + months + " " + getPlural(months) : ""}`
          return {
            id: _id,
            title: subject,
            start: new Date(timeStart),
            end: new Date(timeEnd),
            workStart,
            workLine,
            eventDate: moment(timeStart).format("DD MMMM"),
            ...rest,
          }
        })
        setEvents(filteredEvents)
      } catch (err) {
        console.log("err", err)
      }
    }
    getDates()
  }, [timeFrom])

  useEffect(() => {
    document.getElementsByClassName("Toolbar")[0].children[0].click()
  }, [])

  const eventPropGetter = useCallback((event) => {
    return {
      style: {
        backgroundColor: bgColors[event.id % 6],
      },
    }
  }, [])

  const onSelectEvent = useCallback((event) => setSeceltedEvent(event), [])

  const phoneMask = useMemo(() => {
    if (!selectedEvent || !selectedEvent.teacher || !selectedEvent.teacher.phone) {
      return ""
    }
    const replaced = selectedEvent.teacher.phone.replace(/\D/g, "").match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/)
    return `${replaced[1]} (${replaced[2]}) ${replaced[3]} ${replaced[4]} ${replaced[5]}`
  }, [selectedEvent])

  const onNavigate = (date) => {
    const time = moment(date).startOf("month").unix() * 1000
    if (time !== timeFrom) {
      setTimeFrom(time)
    }
  }

  const closeModal = useCallback((event) => setSeceltedEvent(null), [])

  return (
    <div className={`CalendarBlock ${showMobile && "mobile"}`} id="#schedule">
      <div className="Title">расписание занятий</div>
      <div className="CalendarWrapper">
        <Calendar
          selectable
          localizer={localizer}
          events={events}
          views={["month"]}
          style={{ height: 750 }}
          culture="ru"
          onSelectEvent={onSelectEvent}
          eventPropGetter={eventPropGetter}
          components={{
            toolbar: Toolbar,
            event: MonthEvent,
          }}
          popup={true}
          onNavigate={onNavigate}
        />
        {!showInModal && selectedEvent && (
          <div className="EventWrapper">
            <div className="EventTitle">{selectedEvent.title}</div>
            <div className="EventInfo">
              <div className="EventTeacher">
                <div className="TeacherCardRow">
                  <div>
                    <Avatar size={84} src={selectedEvent.teacher.avatar} />
                  </div>
                  <div className="TeacherInfo">
                    <div className="TeacherName">
                      {selectedEvent.teacher.firstName} {selectedEvent.teacher.lastName}
                    </div>
                    <div className="TeacherDescription">{selectedEvent.teacher.direction}</div>
                    <div className="TeacherWork">
                      <span style={{ textTransform: "capitalize" }}>{selectedEvent.workStart}</span> - работает сейчас
                    </div>
                    <div className="TeacherWorkProgress">{selectedEvent.workLine}</div>
                    <div className="Responsibility">Обязанности:</div>
                    <div className="Responsibilities">{selectedEvent.teacher.responsibilities.join(", ")}</div>
                  </div>
                </div>
                <Divider className={"Divider"} />
                <div className="EventTime">
                  <div className="BigInfo">{selectedEvent.eventDate}</div>
                  <div className="SmallInfo">{selectedEvent.dancehall}</div>
                  <div className="BigInfo">
                    {moment(selectedEvent.start).format("HH-mm")} до {moment(selectedEvent.end).format("HH-mm")}
                  </div>
                </div>
              </div>
            </div>
            {selectedEvent.teacher && selectedEvent.teacher.phone && (
              <div className="TeacherPhone">
                <div className="PhoneInfo">Связаться с тренером:</div>
                <a href={`tel:${selectedEvent.teacher.phone}`} className="PhoneCall">
                  {phoneMask}
                </a>
              </div>
            )}
          </div>
        )}
        {selectedEvent && (
          <Modal visible={showInModal} onOk={closeModal} onCancel={closeModal} footer={null} wrapClassName={"CalendarModal"}>
            <div className="ModalWrapper">
              <div className="ModalTitle">{selectedEvent.title}</div>
              <div className="ModalInfo">
                <div className="ModalTeacher">
                  <div className="TeacherCardRow">
                    <div>
                      <Avatar size={64} src={selectedEvent.teacher.avatar} />
                    </div>
                    <div className="TeacherInfo">
                      <div className="TeacherName">
                        {selectedEvent.teacher.firstName} {selectedEvent.teacher.lastName}
                      </div>
                      <div className="TeacherDescription">{selectedEvent.teacher.direction}</div>
                      <div className="TeacherWork">
                        <span style={{ textTransform: "capitalize" }}>{selectedEvent.workStart}</span> - работает сейчас
                      </div>
                    </div>
                  </div>
                  <div className="TeacherDesc">
                    <div className="TeacherDescItem">
                      <div className="Responsibility">Обязанности:</div>
                      <div className="Responsibilities">{selectedEvent.teacher.responsibilities.join(", ")}</div>
                    </div>
                    <div className="TeacherDescItem">
                      <div className="DateInfo">{selectedEvent.eventDate}</div>
                      <div className="RoomInfo">{selectedEvent.dancehall}</div>
                      <div className="TimeInfo">
                        {moment(selectedEvent.start).format("HH-mm")} до {moment(selectedEvent.end).format("HH-mm")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {selectedEvent.teacher && selectedEvent.teacher.phone && (
                <div className="TeacherPhone">
                  <div className="PhoneInfo">Связаться с тренером:</div>
                  <a href={`tel:${selectedEvent.teacher.phone}`} className="PhoneCall">
                    {phoneMask}
                  </a>
                </div>
              )}
            </div>
          </Modal>
        )}
      </div>
    </div>
  )
}

export default React.memo(CalendarComponent)
